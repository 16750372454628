import React from 'react';
import { useSelector } from 'react-redux';
import AnswerWeb from './AnswerWeb';
import AnswerMobile from './AnswerMobile';
import AnswerLong from './AnswerLong';

import styles from './Answer.module.sass';

export default function Answer() {
  const { data, loading, error } = useSelector(state => state.ask);
  let dataJson, answer, parentResults, parentAnswerField;

  if (loading === 'pending') {
    answer = 'Loading...';
  }

  if (loading === 'idle') {
    answer = data;
    if (answer && answer.length !== 0) {
      dataJson = answer;
      parentAnswerField = dataJson.answer_field;
      parentResults = dataJson.results.filter(result => result[parentAnswerField] !== '');
    }
  }

  if (error !== null) {
    answer = <>{error}</>;
  }

  const isParagraphDisplay = [
    'warnings',
    'contraindications',
    'dosage_information',
    'indications',
    'main_patent_expiry',
    'review_type'
  ].includes(parentAnswerField);
  const isBigScreen = window.innerWidth > 600;
  const answerdFieldAtFirst = (a, b) => (parentAnswerField === a) ? -1 : (parentAnswerField === b) && 1;
  const answerFieldAtLast = (a, b) => (parentAnswerField === a) ? 1 : (parentAnswerField === b) && -1;
  const getTitle = (nResults) => (nResults === 1) ? 'Answer' : (nResults === 0) ? 'We could not find a match for your query. Please try again.' : 'Possible Answers'
  const getClassName = (nResults) => (nResults === 0) ? styles.noresults_title : ''
  return (
    <div>
      {isParagraphDisplay
        ? <AnswerLong parentResults={parentResults} getClassName={getClassName} getTitle={getTitle} answerFieldAtLast={answerFieldAtLast} />
        : isBigScreen
          ? <AnswerWeb parentResults={parentResults} getClassName={getClassName} getTitle={getTitle} answerdFieldAtFirst={answerdFieldAtFirst} />
          : <AnswerMobile parentResults={parentResults} getClassName={getClassName} getTitle={getTitle} answerdFieldAtFirst={answerdFieldAtFirst} />
      }
    </div>
  );
}
