
import AudioRecorder from 'audio-recorder-polyfill';
window.MediaRecorder = AudioRecorder

export default function askRecord() {
  var askRecord = {
    /** Stores the recorded audio as Blob objects of audio data as the recording continues*/
    audioBlobs: [], /*of type Blob[]*/
    /** Stores the reference of the MediaRecorder instance that handles the MediaStream when recording starts*/
    mediaRecorder: null, /*of type MediaRecorder*/
    /** Stores the reference to the stream currently capturing the audio*/
    streamBeingCaptured: null, /*of type MediaStream*/
    /** Start recording the audio
      * @returns {Promise} - returns a promise that resolves if audio recording successfully started
      */
    audioElementSource: null,
    audioElement: document.createElement("audio"),
    /** Start recording the audio
      * @returns {Promise} - returns a promise that resolves if audio recording successfully started
      */
    start: function () {
      //Feature Detection
      if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
        //Feature is not supported in browser
        //return a custom error
        return Promise.reject(new Error('mediaDevices API or getUserMedia method is not supported in this browser.'));
      }
      else {
        //Feature is supported in browser

        //create an audio stream
        return navigator.mediaDevices.getUserMedia({ audio: true }/*of type MediaStreamConstraints*/)
          //returns a promise that resolves to the audio stream
          .then(stream /*of type MediaStream*/ => {

            //save the reference of the stream to be able to stop it when necessary
            askRecord.streamBeingCaptured = stream;

            //create a media recorder instance by passing that stream into the MediaRecorder constructor
            askRecord.mediaRecorder = new MediaRecorder(stream, { bitsPerSecond: 128000 }); /*the MediaRecorder interface of the MediaStream Recording
                        API provides functionality to easily record media*/

            //clear previously saved audio Blobs, if any
            askRecord.audioBlobs = [];

            //add a dataavailable event listener in order to store the audio data Blobs when recording
            askRecord.mediaRecorder.addEventListener("dataavailable", event => {
              //store audio Blob object
              askRecord.audioBlobs.push(event.data);
            });

            //start the recording by calling the start method on the media recorder
            askRecord.mediaRecorder.start();
          });

      }
    },
    /** Stop the started audio recording
     * @returns {Promise} - returns a promise that resolves to the audio as a blob file
     */
    stop: function () {
      //return a promise that would return the blob or URL of the recording
      return new Promise(resolve => {
        //save audio type to pass to set the Blob type
        let mimeType = askRecord.mediaRecorder.mimeType;
        //listen to the stop event in order to create & return a single Blob object
        askRecord.mediaRecorder.addEventListener("stop", () => {
          //create a single blob object, as we might have gathered a few Blob objects that needs to be joined as one
          let audioBlob = new Blob(askRecord.audioBlobs, { type: mimeType });

          // uncomment to play right after record.
          // askRecord.playAudio(audioBlob);

          // resolve promise
          const blobAndMimetype = {
            audioBlob: audioBlob,
            mimeType: mimeType
          }
          resolve(blobAndMimetype);
        });
        askRecord.cancel();
      });
    },
    /** Cancel audio recording*/
    cancel: function () {
      //stop the recording feature
      askRecord.mediaRecorder.stop();

      //stop all the tracks on the active stream in order to stop the stream
      askRecord.stopStream();

      //reset API properties for next recording
      askRecord.resetRecordingProperties();
    },
    /** Stop all the tracks on the active stream in order to stop the stream and remove
     * the red flashing dot showing in the tab
     */
    stopStream: function () {
      //stopping the capturing request by stopping all the tracks on the active stream
      askRecord.streamBeingCaptured.getTracks() //get all tracks from the stream
        .forEach(track /*of type MediaStreamTrack*/ => track.stop()); //stop each one
    },
    /** Reset all the recording properties including the media recorder and stream being captured*/
    resetRecordingProperties: function () {
      askRecord.mediaRecorder = null;
      askRecord.streamBeingCaptured = null;

      /*No need to remove event listeners attached to mediaRecorder as
      If a DOM element which is removed is reference-free (no references pointing to it), the element itself is picked
      up by the garbage collector as well as any event handlers/listeners associated with it.
      getEventListeners(askRecord.mediaRecorder) will return an empty array of events.*/
    },
    playAudio: function (recorderAudioAsBlob) {

      //read content of files (Blobs) asynchronously
      let reader = new FileReader();

      //once content has been read
      reader.onload = (e) => {
        //store the base64 URL that represents the URL of the recording audio
        let base64URL = e.target.result;

        //If this is the first audio playing, create a source element
        //as pre populating the HTML with a source of empty src causes error
        if (!askRecord.audioElementSource) //if its not defined create it (happens first time only)
        askRecord.createSourceForAudioElement();

        //set the audio element's source using the base64 URL
        askRecord.audioElementSource.src = base64URL;

        //set the type of the audio element based on the recorded audio's Blob type
        let BlobType = recorderAudioAsBlob.type.includes(";") ?
          recorderAudioAsBlob.type.substr(0, recorderAudioAsBlob.type.indexOf(';')) : recorderAudioAsBlob.type;
          askRecord.audioElementSource.type = BlobType

        askRecord.playsInline = true;
        //call the load method as it is used to update the audio element after changing the source or other settings
        askRecord.audioElement.load();

        //play the audio after successfully setting new src and type that corresponds to the recorded audio
        console.log("Playing audio...");
        askRecord.audioElement.play();

        // next comented lines allow file to be downloaded
        // const file = new File([recorderAudioAsBlob], "foo.ogg", {type: "audio/webm;codecs=opus"});
        // var newAudioUrl =  URL.createObjectURL(file);
        // document.getElementById('audioDownloadTag').href = newAudioUrl;
      };

      // Kickstart: read content and convert it to a URL (base64)
      reader.readAsDataURL(recorderAudioAsBlob);
    },
    createSourceForAudioElement: function () {
      let sourceElement = document.createElement("source");
      askRecord.audioElement.appendChild(sourceElement);

      askRecord.audioElementSource = sourceElement;
    }
  }


  return askRecord;
}
