import React, { useEffect } from 'react';
import './App.sass';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faMicrophone, faMagnifyingGlass, faX, faStop } from '@fortawesome/free-solid-svg-icons'
import { useAuth0 } from "@auth0/auth0-react";

import Answer from './features/answer/Answer';
import Ask from './features/ask/Ask';
import Footer from './features/footer/Footer';
import Header from './features/header/Header';

library.add([faMicrophone, faMagnifyingGlass, faX, faStop])


function App() {
  const { isLoading, user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  useEffect(() => {
    (async function login() {
      if (!isLoading && !user) {
        try {
          await logout();
          await loginWithRedirect();
        } catch (error) {
          await logout()
        } 
      }
    })();
  }, [isLoading, user, loginWithRedirect, logout]);

  if (isLoading) {
    return <div className='app'></div>;
  }

  return (
    isAuthenticated && (
      <div className='app'>
        <div className='container'>
          <Header />
          <Ask />
          <Answer />
          <Footer />
        </div>
      </div>
    )
  );
}

export default App;
