import React from 'react';
import styles from './Answer.module.sass';

export default function AnswerWeb(props) {
  return (
    <div>
        {!!props.parentResults && <div className={styles.answer}>
          <div className={styles.answer_background_container}>
            <div className={styles.answer_headers}>
              <img alt='HeyFENIX' src='/AF-icon.png'></img>
            </div>
            <p className={`${styles.answers_title} ${props.getClassName(props.parentResults.length)}`}>{props.getTitle(props.parentResults.length)}</p>
            {!!props.parentResults && <table className={styles.table_results}>
              <thead>
                <tr key='headers_id'>
                  {props.parentResults.length > 0 && Object.keys(props.parentResults[0]).sort(props.answerdFieldAtFirst).map(key => (
                    (key !== 'record_id') &&
                    <th key={`${key}H`} className={styles.column_header}>{key.replace('_', ' ')}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {props.parentResults.map((result, i) =>
                  <tr key={result.record_id} className={(i % 2 === 0 ? styles.alternating_row_background_color : '')}>
                    {Object.keys(result).sort(props.answerdFieldAtFirst).map((key, j) => (
                      (key !== 'record_id') &&
                      <td key={`${result.record_id}-${result[key]}`} className={(j === 0 ? styles.red_answer_text : styles.standard_column_text)}>{result[key]}</td>
                    ))}
                  </tr>)}
              </tbody></table>
            }
          </div>
        </div>}
    </div>
  );
}
