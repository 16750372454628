import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchAnswer } from './askAPI';

const initialState = {
  data: '',
  loading: 'idle',
  error: null,
};

export const getAnswer = createAsyncThunk(
  'ask/fetchAnswer',
  async answer => {
    if (answer !== '') {
      const response = fetchAnswer(answer);
      return response;
    } else {
      return ''
    }
  }
);

export const askSlice = createSlice({
  name: 'ask',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAnswer.pending, (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    });
    builder.addCase(getAnswer.fulfilled, (state, action) => {
      if (state.loading === 'pending') {
        state.data = action.payload;
        state.loading = 'idle';
      }
    });
    builder.addCase(getAnswer.rejected, (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.error = 'Error occured';
      }
    });
  },
});

export default askSlice.reducer;
