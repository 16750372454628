import styles from './Header.module.sass';

export default function Header() {
  const goHome = () => {
    window.location = '/';
  }
  return (
    <div className={styles.headerWrapper} onClick={goHome}>
      <img className={styles.logo} src='/HeyFENIX-logo.png' alt='logo' />
      <p className={styles.findanswers}>
        Find answers fast by querying the FENIX platform
      </p>
    </div>
  );
}
