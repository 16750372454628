import React from 'react';
import styles from './Answer.module.sass';

export default function AnswerMobile(props) {
  return (
    <div>
        {!!props.parentResults && <div className={styles.answer}>
          <div className={styles.answer_background_container}>
            <div className={styles.answer_headers}>
              <img alt='HeyFENIX' src='/AF-icon.png'></img>
            </div>
            <p className={`${styles.answers_title} ${props.getClassName(props.parentResults.length)}`}>{props.getTitle(props.parentResults.length)}</p>
            {!!props.parentResults && <table className={styles.table_results}>
              <tbody>
                {props.parentResults.map((result, i) =>
                  <tr key={result.record_id} className={(i % 2 === 0 ? styles.alternating_row_background_color : '')}>
                    <td>
                      <div className={`${styles.mobile_layout}`}>
                        {Object.keys(result).sort(props.answerdFieldAtFirst).map((key, j) => (
                          (key !== 'record_id') &&
                          <div className={styles.divMobileFlex} key={`${result.record_id}-p${j}`}>
                            <span className={styles.span_left}>{key}:</span>&nbsp;
                            <span className={`${styles.span_right} ${(j === 0 ? styles.red_answer_text : styles.standard_column_text)}`}>
                              {result[key]}
                            </span>
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>)}
              </tbody></table>
            }
          </div>
        </div>}
    </div>
  );
}
