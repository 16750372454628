import styles from './Footer.module.sass';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.footerContainer}>
      <p className={styles.usingNlp}>
        Using natural language processing (English only), query the FENIX platform for filing date, approval date, launch date, review type, and more! Past or future queries welcome.
      </p>
      <p className={styles.example}>
        Example: <span className={styles.whenWill}> When will [company name] submit [drug name] to the FDA?</span>
      </p>
      <img className={styles.logo} src='/FENIX-logo.png' alt='logo' />
      <p className={styles.copyright}>
        Copyright © {currentYear} Fenix Group International, LLC
      </p>
    </div>
  );
}
