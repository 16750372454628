import React from 'react';
import styles from './Answer.module.sass';
import { marked } from 'marked';

export default function AnswerLong(props) {
  const renderer = new marked.Renderer()
  const linkRenderer = renderer.link
  
  renderer.link = (href, title, text) => {
    const html = linkRenderer.call(renderer, href, title, text)
    return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ')
  }
  
  const toMarkdown = (str) => {
    if (str) {
      str = marked(str, { renderer })
    }
    return str
  }

  return (
    <div>
      {!!props.parentResults && <div className={styles.answer}>
        <div className={styles.answer_background_container}>
          <div className={styles.answer_headers}>
            <img alt='HeyFENIX' src='/AF-icon.png'></img>
          </div>
          <p className={`${styles.answers_title} ${props.getClassName(props.parentResults.length)}`}>{props.getTitle(props.parentResults.length)}</p>
          {!!props.parentResults && <table className={styles.table_results}>
            <tbody>
              {props.parentResults.map((result, i) =>
                <tr key={result.record_id} className={(i % 2 === 0 ? styles.alternating_row_background_color : '')}>
                  <td>
                    <div className={`${styles.mobile_layout} ${styles.long_margin}`}>
                      {Object.keys(result).sort(props.answerFieldAtLast).map((key, j) => (
                        (j !== (Object.keys(result).length - 1))
                          ?
                          (key !== 'record_id') && <div key={`${result.record_id}-p${j}`}>
                            <span className={styles.span_long_left}>{key}:</span>&nbsp;
                            <span className={`${styles.span_long_right} ${styles.standard_column_text}`}>
                              {result[key]}
                            </span>
                          </div>
                          :
                          (key !== 'record_id') && <div key={`${result.record_id}-p${j}`}>
                            <span className={styles.span_long_title}>{key.replace(/\_/g, ' ')}</span>
                            <div className={`${styles.span_long_right} ${styles.red_answer_text}`} 
                              dangerouslySetInnerHTML={{ __html: toMarkdown(result[key]) }} />
                          </div>
                      ))}
                    </div>
                  </td>
                </tr>)}
            </tbody></table>
          }
        </div>
      </div>}
    </div>
  );
}
