import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getAnswer } from './askSlice';
import styles from './Ask.module.sass';

import askRecord from './askRecord';
export default function Ask() {
  const originalPlaceHolder = 'Speak or type to HeyFENIX';
  const dispatch = useDispatch();
  const askAudioRecorder = askRecord();

  const [searchQuery, setSearchQuery] = useState('');
  const [isExecuted, setIsExecuted] = useState(false);

  const askQuestion = e => {
    e.preventDefault();
    dispatch(getAnswer(searchQuery));
  }

  const currentURL = new URL(document.location);
  const autoAskRef = React.useRef(currentURL.searchParams.get('ask') !== null);
  const isRecognitionStartedRef = React.useRef(false);

  const toggleRecognition = useCallback(() => {
    const inputQuestion = document.getElementById('inputQuestion');
    const iconStop = document.getElementById('iconStop');
    const iconMic = document.getElementById('iconMic');
    const isRecognitionStarted = isRecognitionStartedRef.current;

    const processAudio = (audioAndMime) => {
      //read content of files (Blobs) asynchronously
      let audioBlob = audioAndMime.audioBlob;
      const mimeType = audioAndMime.mimeType;
      let reader = new FileReader();
      const mimeTypeEncoding = {
        'audio/mp4': 'WEBM_OPUS',
        'audio/webm;codecs=opus': 'WEBM_OPUS'
      }

      //once content has been read
      reader.onload = (e) => {
        //store the base64 URL that represents the URL of the recording audio
        let base64URL = e.target.result;
        let audioStr64 = base64URL.split(',')[1];

        //Do something with the recorded audio
        const askfenix_speech_endpoint = `https://askfenix-speechtotext-us562xekma-uk.a.run.app`;
        return fetch(askfenix_speech_endpoint, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Request-Method': 'POST'
          },
          body: JSON.stringify({ "audiobase64": audioStr64, "currentEncoding": mimeTypeEncoding[mimeType] })
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            const voiceTranscript = data.transcription;
            const inputQuestion = document.getElementById('inputQuestion');

            inputQuestion.placeholder = originalPlaceHolder;
            inputQuestion.value = voiceTranscript;
            setSearchQuery(voiceTranscript);
            dispatch(getAnswer(voiceTranscript));
            return data;
          }).catch((error) => {
            console.error(error)
            return { results: [] }
          });
      };

      // Kickstart: read content and convert it to a URL (base64)
      reader.readAsDataURL(audioBlob);
      audioBlob = null;
    };
    if (!isRecognitionStarted) {
      askAudioRecorder.start()
        .then(() => { //on success
          inputQuestion.placeholder = 'Recording in process...';
          console.log("Recording Audio...");
          autoAskRef.current = false;

          inputQuestion.classList.remove(styles.inputStopped)
          inputQuestion.classList.add(styles.inputStarted)
          iconMic.classList.remove(styles.show)
          iconMic.classList.add(styles.hide)
          iconStop.classList.remove(styles.hide)
          iconStop.classList.add(styles.show)
        })
        .catch(error => { //on error
          //No Browser Support Error
          if (error.message.includes("mediaDevices API or getUserMedia method is not supported in this browser.")) {
            console.log("To record audio, use browsers like Chrome and Firefox.");
          }
          console.log("An error occured at start with the error name " + error.name);
        });
    } else {
      askAudioRecorder.stop()
        .then(audioBlob => {
          processAudio(audioBlob);
          inputQuestion.placeholder = 'recording is done, now computing';
          inputQuestion.classList.remove(styles.inputStarted)
          inputQuestion.classList.add(styles.inputStopped)
          iconStop.classList.remove(styles.show)
          iconStop.classList.add(styles.hide)
          iconMic.classList.remove(styles.hide)
          iconMic.classList.add(styles.show)
          
          window.history.pushState({}, "", "/");
        })
        .catch(error => {
          //Error handling structure
          switch (error.name) {
            case 'InvalidStateError': //error from the MediaRecorder.stop
              console.log("An InvalidStateError has occured.");
              break;
            default:
              console.log("An error occured at stop with the error name " + error.name);
          };

        });
    }
    isRecognitionStartedRef.current = !isRecognitionStarted;
  }, [isRecognitionStartedRef, askAudioRecorder, dispatch, autoAskRef]);

  const clearSearch = () => {
    document.getElementById('inputQuestion').value = '';
    setSearchQuery('');
    dispatch(getAnswer(''));
  }

  useEffect(() => {
    setIsExecuted(true);
    if (isExecuted && autoAskRef.current) {
      toggleRecognition();
    }
  }, [isExecuted, autoAskRef, toggleRecognition]);

  return (
    <>
      <div className={styles.questionWrapper}>
        <FontAwesomeIcon className={styles.magGlass} icon='fas fa-magnifying-glass' />
        <form onSubmit={askQuestion}>
          <input
            className={styles.inputStopped}
            type='text'
            id='inputQuestion'
            autoComplete='off'
            placeholder={originalPlaceHolder}
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </form>
        {searchQuery.length === 0 && <button
          id='micBtnId'
          className={styles.micBtn}
          onClick={e => { e.preventDefault(); toggleRecognition(); }}
        >
          <img className={styles.show} id='iconMic' src="/microphone.png" alt='mic' />
          <FontAwesomeIcon id='iconStop' className={styles.hide} icon='fas fa-stop' />
        </button>}
        {searchQuery.length !== 0 && <button
          className={styles.xBtn}
          onClick={e => { e.preventDefault(); clearSearch(); }}
        >
          <FontAwesomeIcon icon='fas fa-x' />
        </button>}
      </div>
    </>
  );
}
