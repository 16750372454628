export function fetchAnswer(req) {
  const apiPath = `https://heyfenix.kiq.app/endpoint`;
  return fetch(apiPath, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'mode': 'cors',
      },
      body: JSON.stringify({"string": `${req.trim()}`})
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    }).catch((error) => {
      console.error(error)
      return {results: []}
    });
}
